const CallSmall = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
                <g clipPath="url(#clip0_22320_64862)">
                    <path
                        d="M2.79343 3.45112C3.02542 3.93432 3.34169 4.3872 3.74221 4.78773C4.14274 5.18826 4.59562 5.50452 5.07883 5.73652C5.12039 5.75647 5.14117 5.76645 5.16747 5.77412C5.26092 5.80136 5.37567 5.78179 5.45482 5.72512C5.47709 5.70917 5.49614 5.69012 5.53424 5.65202C5.65078 5.53548 5.70905 5.47721 5.76764 5.43912C5.9886 5.29545 6.27346 5.29545 6.49443 5.43912C6.55302 5.47722 6.61129 5.53548 6.72782 5.65202L6.79278 5.71697C6.96992 5.89412 7.0585 5.9827 7.10661 6.07782C7.2023 6.26701 7.2023 6.49043 7.10661 6.67962C7.0585 6.77475 6.96993 6.86332 6.79278 7.04047L6.74023 7.09301C6.56369 7.26955 6.47542 7.35782 6.35541 7.42524C6.22224 7.50005 6.01541 7.55383 5.86267 7.55338C5.72502 7.55297 5.63095 7.52627 5.4428 7.47287C4.43167 7.18588 3.47755 6.64438 2.68155 5.84839C1.88556 5.0524 1.34407 4.09828 1.05708 3.08714C1.00368 2.899 0.976975 2.80492 0.976565 2.66727C0.976111 2.51453 1.0299 2.3077 1.1047 2.17453C1.17212 2.05452 1.26039 1.96625 1.43693 1.78971L1.48948 1.73717C1.66662 1.56002 1.7552 1.47144 1.85033 1.42333C2.03951 1.32764 2.26293 1.32764 2.45212 1.42333C2.54725 1.47144 2.63582 1.56002 2.81297 1.73717L2.87793 1.80212C2.99446 1.91866 3.05273 1.97693 3.09082 2.03552C3.23449 2.25648 3.23449 2.54134 3.09082 2.76231C3.05273 2.8209 2.99446 2.87917 2.87793 2.9957C2.83982 3.03381 2.82077 3.05286 2.80482 3.07513C2.74815 3.15427 2.72859 3.26902 2.75583 3.36248C2.76349 3.38877 2.77347 3.40955 2.79343 3.45112Z"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round" />
                </g>
                <defs>
                    <clipPath id="clip0_22320_64862">
                        <rect width="8" height="8" fill="white" transform="translate(0 0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </>
    );
};

export default CallSmall;