import { Dialog } from "@mui/material";
import style from "./ManageSubscription.module.scss";
import Button from "../../atoms/Button";
import Zap from "../../assets/icons/zap";
import Close from "../../assets/icons/close";
import CheckCircleFill from "../../assets/icons/CheckCircleFill";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../atoms/Loader";
import dayjs from "dayjs";
import { ProductPriceIntervalType, ProductType } from "./enum";
import InfoCircle from "../../assets/icons/infoCircle";
import { getLoadingOrgSubscription, getOrganization, getOrgSubscription } from "../../pages/organization/selector";
import { useEffect, useState } from "react";
import { getProfileInfo } from "../../pages/profile/selector";
import { sendActivityLog } from "../auth/service";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import PauseSubscription from "./PauseSubscription";
import { pauseSubscription, resumeSubscription } from "./service";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { CustomError } from "../../models/customError.interface";
import { setOrgSubscription } from "../../pages/organization/reducer";
import { useParams } from "react-router-dom";
import Freetrial from "./Freetrial";

const NoSubscriptionsFallback = ({ label}: any) => {
  return (
    <>
      <div className={`${style.manage__item} dflex alignCenter`}>
        <div className={`${style.manage__info} flex alignCenter`}>
          <span className={`animated-svg color-red`}>
            <InfoCircle />
          </span>
          <aside>
            <strong>{label}</strong>
            <p>No Active Subscription</p>
          </aside>
        </div>
      </div>
    </>
  );
};

const ManageSubscription = ({
  handleClose,
  open,
  onCancelSubscription,
}: any) => {
  const subscriptionIsLoading = useSelector((state) =>
    getLoadingOrgSubscription(state)
  );
  const param = useParams();
  const subscriptions = useSelector((state) => getOrgSubscription(state));
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const orgDetail = useSelector((state) => getOrganization(state));
  const dispatch= useDispatch()
  const [confirmPauseModal, setConfirmPauseModal]= useState(false);
  const [pauseModal, setPauseModal]= useState(false);
  const [loading, setLoading] = useState(false);
  const [generalSubscription, setGeneralSubscription]= useState<Record<string, any>>({});
  const [creditSubscription, setcreditSubscription]= useState<Record<string, any>>({});
  const [vaSubscription, setVaSubscription]= useState<Record<string, any>>({});
  const [showFreeTrialModal, setshowFreeTrialModal] = useState(false);
  const [subscriptionData, setSubscriptionData]= useState<Record<string, any>>({});
  const handleFreeTrialModal = () => {
    setshowFreeTrialModal(!showFreeTrialModal);
  };
  const logAuditData = async (type:any) => {
    const attributes = {
      action: `User is trying to cancel ${type} subscription`,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo?.email,
      roleName: profileInfo?.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType: `User is trying to cancel ${type} subscription`,
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handlePauseModal=()=>{
    setPauseModal(!pauseModal)
    setConfirmPauseModal(true)
  }

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };
  const handleResume=async()=>{
    try {
      setLoading(true);
      await resumeSubscription(subscriptions[1]?.stripeSubscriptionId,param?.orgId);
      setLoading(false);
      handleShowToast("Subscription resumed successfully!", ToastType.success);
      dispatch(setOrgSubscription({
        ...subscriptions,
        [1]: {
          ...subscriptions[1],
          isPaused: 0
        }
      }));
    } catch (error) {
      setLoading(false);
      handleShowToast(
        (error as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  }

  useEffect(()=>{
   if(Object.keys(subscriptions).length >0){
    const generalSubscriptionPlan = subscriptions.find(
      (sub:any) => sub?.productType === "general_access"
    )
    setGeneralSubscription(generalSubscriptionPlan)
     const creditAccessSubscription = subscriptions.find(
      (sub:any) => sub?.productType === "credit_access"
    )
    setcreditSubscription(creditAccessSubscription)
    const vaAccessSubscriptionPlan = subscriptions.find(
      (sub:any) => sub?.productType === "va_access"
    )
    setVaSubscription(vaAccessSubscriptionPlan)

  }
  },[subscriptions])
  
  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "View user current subscriptions",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "View user current subscriptions",
            jsonData: result,
          });
          console.log(res);
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    if(open){
      logAuditData();
    }
  }, [profileInfo,open]);

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={open}
        className={`stepsDialog subscriptionDialog manageSubscription`}
      >
        {subscriptionIsLoading ? (
          <div className={`table-loader`}>
            <Loader></Loader>
          </div>
        ) : (
          <div className={style.manage}>
            <div className={`${style.manage__header} flex justifySpaceBetween`}>
              <CheckCircleFill />
              <span role="link" className="closeDialog" onClick={handleClose}>
                <Close />
              </span>
            </div>
            <h4>Manage user subscriptions</h4>
            {vaSubscription ? (
              <div className={`${style.manage__item} dflex alignCenter`}>
                <div className={`${style.manage__info} flex alignCenter`}>
                  <span className={`animated-svg `}>
                    <InfoCircle />
                  </span>
                  <aside>
                    <strong>{vaSubscription.productName}</strong>
                    <p>
                      {vaSubscription.isPaused ? "" : `Renews ${" "}`}
                      {vaSubscription.isPaused
                        ? "Paused"
                        : vaSubscription?.expiresAt
                        ? dayjs(vaSubscription?.expiresAt).format(
                            `MMM DD, YYYY`
                          )
                        : "-"}
                    </p>
                  </aside>
                </div>

                <div className={`${style.manage__action} flex`}>
                  <Button
                    className="primary animated"
                    label="Start free trial"
                    action={() => {
                      handleFreeTrialModal();
                      setSubscriptionData(vaSubscription);
                    }}
                  ></Button>
                  <Button
                    label="Cancel"
                    className="outline md"
                    action={() => {
                      logAuditData(ProductType?.VA_ACCESS);
                      onCancelSubscription(
                        vaSubscription,
                        ProductType.VA_ACCESS
                      );
                    }}
                  />
                </div>
              </div>
            ) : (
              <NoSubscriptionsFallback
                label="VA Subscription"
                productType={ProductType.VA_ACCESS}
              ></NoSubscriptionsFallback>
            )}

            {creditSubscription ? (
              <div className={`${style.manage__item} dflex alignCenter`}>
                <div className={`${style.manage__info} flex alignCenter`}>
                  <span className={`animated-svg `}>
                    <InfoCircle />
                  </span>
                  <aside>
                    <strong>{creditSubscription.productName}</strong>
                    <p>
                      {creditSubscription.isPaused ? "" : `Renews ${" "}`}
                      {creditSubscription.isPaused
                        ? "Paused"
                        : creditSubscription?.expiresAt
                        ? dayjs(creditSubscription?.expiresAt).format(
                            `MMM DD, YYYY`
                          )
                        : "-"}
                    </p>
                  </aside>
                </div>

                <div className={`${style.manage__action} flex`}>
                  <Button
                    className="primary animated"
                    label="Start free trial"
                    action={() => {
                      handleFreeTrialModal();
                      setSubscriptionData(creditSubscription);
                    }}
                  ></Button>
                  <Button
                    label="Cancel"
                    className="outline md"
                    action={() => {
                      logAuditData(ProductType?.CREDIT_ACCESS);
                      onCancelSubscription(
                        creditSubscription,
                        ProductType.CREDIT_ACCESS
                      );
                    }}
                  />
                  {creditSubscription?.isPaused ? (
                    <Button
                      label="Resume"
                      className="outline md"
                      action={() => {
                        handleResume();
                      }}
                      clicked={loading}
                      disabled={loading}
                    />
                  ) : (
                    <Button
                      label="Pause"
                      className="outline md"
                      action={() => {
                        handlePauseModal();
                      }}
                    />
                  )}
                </div>
              </div>
            ) : (
              <NoSubscriptionsFallback
                label="AI Calling Subscription"
                productType={ProductType.CREDIT_ACCESS}
              ></NoSubscriptionsFallback>
            )}
            {generalSubscription ? (
              <>
                <div className={`${style.manage__item} dflex alignCenter`}>
                  <div className={`${style.manage__info} flex alignCenter`}>
                    {ProductPriceIntervalType.YEAR ===
                    generalSubscription?.interval ? (
                      <span className={`animated-svg `}>
                        <InfoCircle />
                      </span>
                    ) : (
                      <span className={`animated-svg color-red`}>
                        <InfoCircle />
                      </span>
                    )}
                    <aside>
                      <strong>{generalSubscription?.productName}</strong>
                      <p>
                        Renews{" "}
                        {generalSubscription?.expiresAt
                          ? dayjs(generalSubscription?.expiresAt).format(
                              `MMM DD, YYYY`
                            )
                          : "-"}
                      </p>
                    </aside>
                  </div>

                  <div className={`${style.manage__action} flex`}>
                    <Button
                      className="primary animated"
                      label="Start free trial"
                      action={() => {
                        handleFreeTrialModal();
                        setSubscriptionData(generalSubscription);
                      }}
                    ></Button>

                    <Button
                      label="Cancel"
                      className="outline md"
                      action={() => {
                        logAuditData(ProductType?.GENERAL_ACCESS);
                        onCancelSubscription(generalSubscription);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <NoSubscriptionsFallback
                label="General access Subscription"
                productType={ProductType.GENERAL_ACCESS}
              ></NoSubscriptionsFallback>
            )}
          </div>
        )}
      </Dialog>
      <PauseSubscription
        handleClose={() => {
          setPauseModal(false);
        }}
        open={pauseModal}
        setPause={setPauseModal}
        subscriptions={subscriptions}
        confirmPauseModal={confirmPauseModal}
        setConfirmPauseModal={setConfirmPauseModal}
        creditUsage={orgDetail}
      ></PauseSubscription>
      <Freetrial
        handleClose={() => {
          setshowFreeTrialModal(false);
        }}
        open={showFreeTrialModal}
        subscriptionData={subscriptionData}
      ></Freetrial>
    </>
  );
};

export default ManageSubscription;
