import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaAction";
import { getAPI } from "../../apis/apis";
import { addToast } from "../../molecules/toaster/toastSlice";
import { ToastType } from "../../molecules/toaster/enum";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchMyList({ payload }: any) {
  const { index, size, orgId ,search} = payload;
  if (index === 1) {
    yield put(slice.setListLoading(true));
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/campaign/lead-list/all?index=${index}&size=${size}&organisationId=${orgId}&search=${search?.trim()}&type=${
        payload?.type
      }`,
      headers: {},
    });
    yield all([
      put(slice.setListLoading(false)),
      put(
        payload?.index > 1
          ? slice.appendMyLists(response?.data?.result)
          : slice.setMyLists(response?.data?.result)
      ),
      put(slice.setListCount(response?.data?.total)),
    ]);
  } catch (error: any) {
    yield all([
      put(slice.setListLoading(false)),
      put(
        addToast({
          id: new Date().getTime(),
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message ?? "Something went wrong!",
          type: ToastType.error,
        })
      ),
    ]);
  }
}

function* fetchLeadListDetail({ payload }: any) {
  try {
    const {id,orgId, filter}=payload
   if(filter?.skip === 1){ yield put(slice.setLeadListDetailLoading(true))}
    const response: ResponseGenerator = yield getAPI({
      url: `${process.env.REACT_APP_BASE_URL}/admin/campaign/list/${id}/property/${orgId}`,
      data: {
        ...filter,
      }, 
      headers: {},
    });
      yield all([
        put(
          filter?.skip > 1
            ? slice.appendLeadListDetail(response?.data?.results)
            : slice.setLeadListDetail(response?.data?.results)
        ),
        put(slice.setCallAttemptsCount(response?.data?.attemptCountFilter)),
        put(slice.setLeadListDetailCount(response?.data)),
        put(slice.setLeadListDetailLoading(false)),
      ]);
  } catch (error: any) {
       yield all([
         put(slice.setLeadListDetailLoading(false)),
         put(
           addToast({
             id: new Date().getTime(),
             message: error?.response?.data?.message?error?.response?.data?.message : error?.message ?? "Something went wrong!",
             type: ToastType.error,
           })
         ),
       ]);
  }
}

function* myListsSaga() {
  yield takeLatest(sagaActions.fetchList, fetchMyList);
  yield takeLatest(sagaActions.fetchLeadListDetail, fetchLeadListDetail);
}

export default myListsSaga;
