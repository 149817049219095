import { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../atoms/Loader";
import styles from "./ListDetail.module.scss";
import ArrowLeft from "../../assets/icons/arrowLeft";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import {
  getcallAttemptsCount,
  getLeadsListDetail,
  getListDetail,
  getListDetailLoading,
} from "../../organisms/list/selector";
import PropertyCard from "../propertyDetail/PropertyCard";
import { setSelectedLeadId } from "../../organisms/list/reducer";
import { fetchPropertyDetail } from "../propertyDetail/sagaAction";
import { fetchLeadListDetail } from "../../organisms/list/sagaAction";
import Button from "../../atoms/Button";
import MultiSelectDropDown from "../../atoms/MultiSelectDropDown";
import FilterDropdown from "../../organisms/filterDropdown/FilterDropdown";
import Funnel from "../../assets/icons/funnel";
import Circle from "../../assets/icons/circle";
import SearchReflation from "../../assets/icons/searchReflation";
import { useForm } from "react-hook-form";

const ListDetail = () => {
  const param = useParams();
  const { reset, control, getValues,setValue } = useForm({
    mode: "onSubmit",
    defaultValues: {
      callAttempts: [],
    },
  });
  const { orgId, id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listDetailRes = useSelector((state) => getLeadsListDetail(state));
  const callAttemptCount = useSelector((state) =>getcallAttemptsCount(state));
  const listDetail = useSelector((state) => getListDetail(state));
  const listDetailLoading = useSelector((state) => getListDetailLoading(state));
  const [selectedProperty, setSelectedProperty] = useState<any>(null);
  const [propertyIndex, setPropertyIndex] = useState(-1);
  const [filterFlag, setFilterFlag] = useState(false);
  const [applyClicked, setApplyClicked] = useState(false);
  const [callAttemptsCount, setCallAttemptsCounts] = useState([]);
  const filterButtonRef = useRef<HTMLElement | null>(null);
  const [showfilterBtnDropdown, setShowFilterBtnDropdown] =
  useState<boolean>(false);

  const [filter, setFilter] = useState<{
    skip: number;
    count: number;
    vaCallAttempt?: string[];
  }>({
    skip: 1,
    count: 11,
    vaCallAttempt: [],
  });

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, skip: prev?.skip + 1 }));
  };
  
  const handlePropertyClick = (property: any) => {
    setSelectedProperty(property);
    dispatch(setSelectedLeadId(property?.leadId));
    navigate(`/organisation/${orgId}/list/${id}/listDetail/${property?.leadId}`);
    dispatch(
      fetchPropertyDetail({ propertyId: property?.leadId, orgId: orgId })
    );
  };

  const handleFilterButtonClick: MouseEventHandler<HTMLElement> = (e) => {
    filterButtonRef.current = e.currentTarget;
    setShowFilterBtnDropdown(true);
  };

  const handleAttemptClose = () => {
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
    setApplyClicked(false);
  };

  const handleAttemptApplyClose = () => {
    setApplyClicked(true);
    if (
      getValues("callAttempts").length !== 0 
    ) {
      setFilterFlag(true);
    }
    setFilter({ ...filter, skip: 1,vaCallAttempt: getValues("callAttempts") });
    filterButtonRef.current = null;
    setShowFilterBtnDropdown(false);
  };

  useEffect(() => {
    if (!param.id) return;
    const payload = { id: id, orgId: orgId, filter };
    dispatch(fetchLeadListDetail(payload));
  }, [dispatch, param.id, filter]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedLeadId(""));
    };
  }, []);

  useEffect(() => {
    const options = callAttemptCount.filter((list: any) => list.vaAttemptCount !== 0).map((list: any) => ({
        value: list.vaAttemptCount,
        label: list.vaAttemptCount,
    }));
    setCallAttemptsCounts(options);
}, [callAttemptCount]);

  return (
    <>
      {listDetailLoading ? (
        <div className={` ${styles.listDetailLoader}`}>
          <Loader></Loader>
        </div>
      ) : (
        <div className={` ${styles.listDetail}`}>
          <div className={` ${styles.listDetail__header}`}>
            <ul className="flex">
              <li>
                <span
                  role="link"
                  className={` ${styles.propertyHeader__leftBack} flex alignCenter`}
                  onClick={() => navigate(`/organisation/${orgId}/list`)}
                >
                  <ArrowLeft />
                  Lists
                </span>
              </li>
              <li>
                <ArrowRightIcon />
              </li>
              <li>{listDetail?.name}</li>
            </ul>
          </div>
          {Object.keys(callAttemptCount).length > 1 && (
            <div className={` ${styles.listDetail__filter} dflex justifyEnd`}>
              <Button
                label="Filter"
                className="outline filter-icon"
                prefix={<Funnel />}
                action={handleFilterButtonClick}
                postfix={filterFlag ? <Circle color="#D92D20" /> : null}
              />
            </div>
          )}
          {showfilterBtnDropdown && (
            <FilterDropdown
              handleClose={handleAttemptClose}
              anchorEl={filterButtonRef.current}
            >
              <div className={`filterMenu`}>
                <div className="filterMenu__info">
                  <div
                    className={`filterMenu__list`}
                    data-testid="SelectStatusTestId"
                  >
                    <p>Call Attempts</p>
                    <MultiSelectDropDown
                      options={callAttemptsCount}
                      label="Select call attempts"
                      control={control}
                      name="callAttempts"
                      emptySearchMetaData={{
                        icon: <SearchReflation />,
                        title: "No results found",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={`dflex filterMenuFooter`}>
                <div className={`filterMenuFooter__half`}>
                  <Button
                    label="Reset"
                    className="outline full"
                    action={() => {
                      setCallAttemptsCounts([]);
                      reset({
                        callAttempts: [],
                      });
                      setFilter({
                        skip: 1,
                        count: 11,
                        vaCallAttempt: [],
                      });
                      setFilterFlag(false);
                      setShowFilterBtnDropdown(false);
                    }}
                  />
                </div>
                <div className={`filterMenuFooter__half`}>
                  <Button
                    label="Apply"
                    className="primary full"
                    action={handleAttemptApplyClose}
                    dataTestId={"ApplyFilterBtnId"}
                  />
                </div>
              </div>
            </FilterDropdown>
          )}
          <div>
            <InfiniteScroll
              dataLength={listDetailRes?.length}
              next={() => handleNextPage()}
              hasMore={listDetailRes?.length < listDetail?.totalCount}
              loader={
                <div className="table-loader w-full">
                  <Loader></Loader>
                </div>
              }
            >
              <div className={` ${styles.listDetail__list} dflex`}>
                {listDetailRes?.map((property: any, index: number) => {
                  return (
                    <div className={` ${styles.listDetail__card} dflex`}>
                      <PropertyCard
                        property={property}
                        handleMouseHover={() => {}}
                        handleMouseLeave={() => {}}
                        selectedProperty={selectedProperty}
                        handlePropertyClick={handlePropertyClick}
                        propertiesInView={[]}
                        status={property?.leadStatus}
                        callAttempts={property?.vaCallAttempt}
                      ></PropertyCard>
                    </div>
                  );
                })}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      )}
    </>
  );
};

export default ListDetail;
