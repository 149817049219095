import React, { useCallback, useEffect, useState } from "react";

import { getLogAttemptList,
  getLogAttemptListLoading,
  getLogAttemptListCount,
} from "./selector";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CustomTable from "../../molecules/table/CustomTable";
import Loader from "../../atoms/Loader";
import { fetchLogAttemptsList } from "./sagaAction";
import { callAttemptsOptions, columns } from "./constants";
import { followUpDateFormatter } from "../../utils/utils";
import { useForm } from "react-hook-form";
import VoiceMail from "../../assets/icons/voiceMail";
import Disconnected from "../../assets/icons/disconnected";
import WrongNumber from "../../assets/icons/wrongNumber";
import DidNotPickUp from "../../assets/icons/didNotPickUp";
import NotInterested from "../../assets/icons/notInterested ";
import DNC from "../../assets/icons/dnc";
import CallBackLater from "../../assets/icons/callBackLater";
import Verified from "../../assets/icons/verified";
import HangUp from "../../assets/icons/hungUp";
import SearchReflation from "../../assets/icons/searchReflation";
import dayjs from "dayjs";


export const CALL_ATTEMPT_STATUS_ICONS = {
  LeftVoiceMail: <VoiceMail />,
  Disconnected: <Disconnected />,
  WrongNumber: <WrongNumber />,
  DoNotPickUp: <DidNotPickUp />,
  HungUp: <HangUp />,
  NotInterested: <NotInterested />,
  DNC: <DNC />,
  CallBackLater: <CallBackLater />,
  Verified: <Verified />,
};

const callAttemptsStatusOptions = Object.entries(callAttemptsOptions).map(
  ([key, value]) => ({
    label: key,
    value: value,
    prefix: CALL_ATTEMPT_STATUS_ICONS[value],
  })
);

const LogAttempts = () => {
  const {
    formState: { errors },
    control,setValue
  } = useForm({
    mode: "onSubmit",
  });
  const dispatch = useDispatch();
  const { orgId } = useParams();
  const attemptsLoading = useSelector((state) => getLogAttemptListLoading(state));
  const attemptsCount = useSelector((state) => getLogAttemptListCount(state));
  const logAttemptList = useSelector((state) => getLogAttemptList(state));
  const [detail, setDetail] = useState<Record<string, any>[]>([]);
  const [filter, setFilter] = useState({ page: 1, size: 20 });
  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
  };
  
  const displayListData = useCallback(() => {
    const userListData = logAttemptList?.map((list: any) => {
      return {
        id:list?.id,
        name: `${list?.createdByFullName}`,
        email: list?.createdByEmail,
        attempt:list?.attempt,
        address:list?.fullAddress || "-",
        createdAt:
                list?.createdAt
                        ? dayjs(list?.createdAt).format(`MMM DD, YYYY hh:mm A`)
                        : "",
        summary:list?.summary || "-",
        callStatus:list?.callAttempts
      };
    });
    setDetail(userListData ?? []);
  }, [logAttemptList]);


  useEffect(() => {
    displayListData();
  }, [logAttemptList]);

  useEffect(() => {
    Object.keys(logAttemptList).forEach((attempt) => {
      logAttemptList[attempt].callAttempts.forEach((item: any) => {
        setValue(`status_${item.id}`, item.status);
      });
    });
  }, [logAttemptList, setValue]);


  useEffect(() => {
      const payload = {
        id: orgId,
        size: filter.size,
        page: filter.page,
      };
    dispatch(fetchLogAttemptsList(payload));

    }, [filter,orgId]);
  return (
    <> 
      {attemptsLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        // <div className={`${styles.userDetail} `}>
        <div className="log-attempts">
          <CustomTable
            rows={detail}
            columns={columns}
            search={""}
            tableStriped
            hasMore={detail?.length < attemptsCount}
            handleNextPage={handleNextPage}
            callAttemptsStatusOptions={callAttemptsStatusOptions}
            control={control}
            emptyTableMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></CustomTable>
        </div>
      )}
    </>
  );
};

export default LogAttempts;
