import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SideBar from "./components/sideBar/SideBar";
import Organization from "./pages/organization/Organization";
import BillingHistory from "./organisms/billingHistory/BillingHistory";
import List from "./organisms/list/List";
import Leads from "./organisms/leads/Leads";
import Campaigns from "./organisms/campaigns/Campaigns";

import LogOutWrapper from "./organisms/auth/LogoutWrapper";
import LoginWrapper from "./organisms/auth/LoginWrapper";
import Users from "./pages/users/Users";
import OrganizationDetails from "./pages/organizationDetail/OrganizationDetails";
import CampaignsDetails from "./pages/campaignDetails/CampaignsDetails";
import RolesAndPermission from "./pages/rolesAndPermission/RolesAndPermission";
import LandingPage from "./pages/landingPage/LandingPage";
import UserDetail from "./pages/userDetail/UserDetail";
import PropertyDetail from "./pages/propertyDetail/PropertyDetail";
import Page404 from "./pages/404/404";
import AIBotCalling from "./pages/aiBotCalling/AIBotCalling";
import LogAttempts from "./pages/logAttempts/LogAttempts";
import CouponsTab from "./pages/couponsTab/CouponsTab";
import ListDetail from "./pages/listDetail/ListDetail";

const Routing = () => {
  return (
    <BrowserRouter>
      <>
        {/* <LoginWrapper> 
        <SideBar /> 
        </LoginWrapper> */}
        <Routes>
        <Route path="*" element={<Page404></Page404>}></Route>
          <Route
            path="/"
            element={
              <LogOutWrapper>
                <LandingPage />
              </LogOutWrapper>
            }
          ></Route>
          <Route
            path="/organisation"
            element={
              <LoginWrapper>
                <Organization />
              </LoginWrapper>
            }
          ></Route>
          <Route
            path="/users"
            element={
              <LoginWrapper>
                <Users />
              </LoginWrapper>
            }
          ></Route>
          <Route
            path="/roles-permission"
            element={
              <LoginWrapper>
                <RolesAndPermission />
              </LoginWrapper>
            }
          ></Route>
          {/* <Route
            path="/organization/:orgId/campaigns/:campaignId"
            element={
              <LoginWrapper>
                <CampaignsDetails />
              </LoginWrapper>
            }
          /> */}
          <Route
            path="/bot-calling"
            element={
              <LoginWrapper>
                <AIBotCalling />
              </LoginWrapper>
            }
          ></Route>
          <Route
            path="/coupon"
            element={
              <LoginWrapper>
                <CouponsTab />
              </LoginWrapper>
            }
          ></Route>
          <Route
            path="/organisation/:orgId"
            element={
              <LoginWrapper>
                <OrganizationDetails/>
              </LoginWrapper>
            }
          >
             <Route path="campaigns" element={<Campaigns />} />
          <Route path="campaigns/:id" element={<CampaignsDetails />} />
          <Route path="leads" element={<Leads />} />
          <Route path="leads/:id" element={<PropertyDetail />} />
          <Route path="list" element={<List />} />
          <Route path="list/:id" element={<ListDetail />} />
          <Route path="list/:id/listDetail/:listid" element={<PropertyDetail />} />
          <Route path="billing-history" element={<BillingHistory />} />
          <Route path="user-detail" element={<UserDetail />} />
          <Route path="log-Attempt" element={<LogAttempts />} />
            </Route>
        </Routes>
      </>
    </BrowserRouter>
  );
};

export default Routing;
