import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  listLoading: false,
  myLists: [] as any[],
  listCount: 0,
  listdetailLoading:false,
  leadListDetail: [] as any[],
  selectedLeadId: "",
  callAttemptsCount: [] as any[]
};

const myListsTableSlice = createSlice({
  name: "myListsTable",
  initialState,
  reducers: {
    setMyLists: (state, { payload }) => {
      return {
        ...state,
        myLists: payload ?? [],
      }; 
    },
    appendMyLists: (state, { payload}) => {
      return {
        ...state,
        myLists: [...state.myLists, ...(payload ? payload : [])],
      };
    },
    setListCount: (state, { payload }) => {
        return {
          ...state,
          listCount: payload ?? [],
        };
      },
    setListLoading: (state, { payload }) => {
      return { ...state, listLoading: payload };
    },
    resetMyListsTableState: (state, { payload }) => {
      return initialState;
    },
    setLeadListDetailLoading: (state, { payload }) => {
      return { ...state, listdetailLoading: payload };
    },
    setLeadListDetail: (state, { payload}) => {
      return { ...state, leadListDetail: payload };
    },
    appendLeadListDetail: (state, { payload }) => {
      return {
        ...state,
        leadListDetail: [...state?.leadListDetail,...payload],
      };
    },
    setCallAttemptsCount: (state, { payload}) => {
      return { ...state, callAttemptsCount: payload };
    },
    setLeadListDetailCount: (state, { payload}) => {
      return { ...state, listDetail: payload };
    },
    setSelectedLeadId: (state, { payload }) => {
      return { ...state, selectedLeadId: payload };
    },
  },
});

export const {
  setMyLists,
  setListLoading,
  appendMyLists,
  resetMyListsTableState,setCallAttemptsCount,setLeadListDetailCount,setSelectedLeadId,
  setListCount,setLeadListDetailLoading,appendLeadListDetail,setLeadListDetail
} = myListsTableSlice.actions;

export default myListsTableSlice.reducer;
