import React, { useCallback, useEffect, useRef, useState } from "react";
import CustomTable from "../../molecules/table/CustomTable";
import { column, columns, skipList_column } from "./constants";
import {
  formartNumberAsLocalString,
  timeAgoFormatter,
} from "../../utils/utils";
import { CircularProgress, Tooltip } from "@mui/material";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import AlertTriangle from "../../assets/icons/alertTriangle";
import Upload from "../../assets/icons/upload";
import HeroSection from "../heroSection/HeroSection";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchList } from "./sagaAction";
import { getListLoading, getMyListCount, getMylistData } from "./selector";
import Loader from "../../atoms/Loader";
import SearchReflation from "../../assets/icons/searchReflation";
import { fetchExportCSVList } from "./service";
import SearchBar from "../../atoms/SearchBar";
import Button from "../../atoms/Button";
import { sendActivityLog } from "../auth/service";
import { getProfileInfo } from "../../pages/profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import styles from "./List.module.scss";
import { useDbounce } from "../../hooks/debounce.hook";
import { ListHeader } from "../../components/listHeader/ListHeader";

const List = () => {
  const profileInfo = useSelector((state) => getProfileInfo(state));

  const [filter, setFilter] = useState<{
    index: number;
    size: number;
    search: string;
  }>({
    index: 1,
    size: 20,
    search: "",
  });

  const setSearchText = (value: string) => {
    setFilter((prev) => ({ ...prev, search: value, index: 1 }));
  };

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, index: prev.index + 1 }));
  };

  useEffect(() => {
    const logAuditData = async () => {
      const attributes = {
        action: "List page viewed",
        userId: profileInfo?.id,
        uuid: "99",
        email: profileInfo?.email,
        roleName: profileInfo?.roleName,
      };
      try {
        const result = await myAuditLogs(attributes);
        console.log(result);
        try {
          const res = await sendActivityLog({
            logType: "List page viewed",
            jsonData: result,
          });
        } catch (error) {
          console.error("Error logging audit data:", error);
        }
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    };
    logAuditData();
  }, [profileInfo]);

  return (
    <div className={`${styles.list}`}>
      <HeroSection title="List"></HeroSection>
      <div className={`${styles.list__filters}`}>
        <SearchBar
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            let value = e.target.value;
            if (
              e.target.value.length > 0 &&
              e.target.value.trim().length === 0
            ) {
              value = "";
            }
            if (filter.search !== value) {
              setSearchText(value);
            }
          }}
          searchText={filter.search}
        ></SearchBar>
      </div>
      <div className={` ${styles.myList__table}`}>
        <ListHeader
          filter={filter}
          handleNextPage={handleNextPage}
          handleClearSearch={() => setSearchText("")}
          setFilter={setFilter}
        ></ListHeader> 
      </div>
    </div>
  );
};

export default List;
