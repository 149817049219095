import { takeLatest, put, all } from "redux-saga/effects";
import * as slice from "./reducer";
import * as sagaActions from "./sagaAction";
import { getAPI, postAPI } from "../../apis/apis";

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

function* fetchProductList({ payload }: any) {
  const { limit } = payload;
  yield put(slice.setProductListLoading(true));

  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/stripe-products`,
      headers: {},
      data: {},
    });
    yield all([
      put(slice.setProductList(response?.data?.data)),
      put(slice.setProductListLoading(false)),
    ]);
  } catch (error: any) {
    yield all([put(slice.setProductListLoading(false))]);
  }
}

function* fetchCustomerList({ payload }: any) {
  const { size,index,search } = payload;
  if( index === 1){
  yield put(slice.setCustomerListLoading(true))}
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/stripe-resources?size=${size}&index=${index}${search ? `&search=${search}` : ""} `,
      headers: {},
      data: {},
    });
    yield all([
      put(
        index !== 1
          ? slice.appendCustomerList(response?.data?.data?.items)
          : slice.setCustomerList(response?.data?.data?.items)
      ),
      put(slice.setCustomerListTotal(response?.data?.data?.total)),
      put(slice.setCustomerDetail(response?.data?.data)),
      put(slice.setCustomerListLoading(false)),
    ]);
  } catch (error: any) {
    yield all([put(slice.setCustomerListLoading(false))]);
  }
}

function* fetchCouponList({ payload }: any) {
  const { limit, startingAfter } = payload;
  if (startingAfter === "") {
    yield put(slice.setCouponListLoading(true));
  }
  try {
    const response: ResponseGenerator = yield getAPI({
      url: process.env.REACT_APP_BASE_URL + `/admin/stripe-coupons?limit=${limit}${startingAfter ? `&startingAfter=${startingAfter}` : ""}`,
      headers: {},
      data: {},
    });
    yield all([
      put(
        startingAfter !== ""
          ? slice.appendCouponList(response?.data?.data?.items)
          : slice.setCouponList(response?.data?.data.items)
      ),
      put(slice.setCouponDetail(response?.data?.data)),
      put(slice.setCouponListLoading(false)),
    ]);
  } catch (error: any) {
    yield all([put(slice.setCouponListLoading(false))]);
  }
}

function* CouponSaga() {
  yield takeLatest(sagaActions.fetchProductList, fetchProductList);
  yield takeLatest(sagaActions.fetchCouponList, fetchCouponList);
  yield takeLatest(sagaActions.fetchCustomerList, fetchCustomerList); 
}
export default CouponSaga;
