import { createAction } from "@reduxjs/toolkit";

export const fetchList = createAction(
    "FETCH_LIST",
    function prepare(data) {
      return { payload: data };
    }
  );

  export const fetchLeadListDetail=  createAction(
    "FETCH_LEAD_LIST_DETAIL",
    function prepare(data) {
      return { payload: data };
    }
  )