import { initialState } from "./reducer";

const getStateData = (state: any) => state["myListsTable"] || initialState;

export const getMylistData = (state: any) => {
  const reducer = getStateData(state);
  return reducer.myLists;
};

export const getListLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listLoading;
};

export const getMyListCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listCount;
};

export const getLeadsListDetail = (state: any) => {
  const reducer = getStateData(state);
  return reducer.leadListDetail;
};

export const getListDetail = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listDetail;
};

export const getListDetailLoading = (state: any) => {
  const reducer = getStateData(state);
  return reducer.listdetailLoading;
};

export const getcallAttemptsCount = (state: any) => {
  const reducer = getStateData(state);
  return reducer.callAttemptsCount;
};