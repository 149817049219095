import { useSelector } from "react-redux";
import SearchReflation from "../../../../assets/icons/searchReflation";
import EmptyPlaceholder from "../../../../atoms/EmptyPlacholder";
import InfoCard from "../../../../components/infoCard/InfoCard";
import ShadowBox from "../../../../components/shadowBox/ShadowBox";
import {
  formatCurrency,
  formatMonthYear,
  formatPhoneNumber,
  formatPropertyType,
} from "../../../../utils/utils";
import styles from "./MlsTab.module.scss";
import { getPropertyDetailsData } from "../../selector";
const MlsTab = () => {
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const { mlsDetails,generalInfo } = propertyDetails;

  return (
    <div className={` ${styles.mls}`}>
        {mlsDetails?.length === 0 && (
          <EmptyPlaceholder
            icon={<SearchReflation />}
            title={"No results found"}
            description="We couldn’t find any results that match your search or filtering criteria."
          />
        )}
       <ShadowBox
            title={mlsDetails?.failedListingDate ? formatMonthYear(mlsDetails?.failedListingDate) :""}
            subTitle={
                "Most recent MLS data for this property."
            }
         
          >
            <div className={`dflex ${styles.row}`}>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Status Date"
                  description={formatMonthYear(mlsDetails?.failedListingDate, false)}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Price"
                  description= {mlsDetails?.price ? `$${mlsDetails.price}` : "-"}
                
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Status"
                  description={
                    mlsDetails?.status ? mlsDetails?.status: "-"
                  }
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Days on Market"
                  description={mlsDetails.daysOnMarket ? mlsDetails?.daysOnMarket:"-"}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Bed/Bath"
                  description={`${+mlsDetails?.bathroomCount} / ${+mlsDetails?.bathroomCount}`}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard title="MLS/Listing" description={mlsDetails?.mlsNumber} />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Property Sub Type"
                  description={
                    formatPropertyType(generalInfo?.propertySubtype)
                  
                  }
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard title="Agent Name" description={mlsDetails?.agents?.[0].name} />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Agent Phone"
                  description={formatPhoneNumber(mlsDetails?.agents?.[0].primaryPhoneNumber)}
                />
              </div>
              <div className={` ${styles.row__col}`}>
                <InfoCard title="Office Name" description={mlsDetails?.agentOffices?.[0].name} />
              </div>

              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Date Sold"
                  description={formatMonthYear(mlsDetails?.soldDate, false)}
                />
              </div>

              <div className={` ${styles.row__col}`}>
                <InfoCard
                  title="Sold Price"
                  description={mlsDetails?.soldPrice ? `$${mlsDetails.soldPrice}` : "-"}
                />
              </div>
            </div>
          </ShadowBox>
      </div>
  );
};
export default MlsTab;
