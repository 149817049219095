import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { ListsTabs } from "./enum";

import styles from "./ListHeader.module.scss";
import ListTable from "../../pages/listTable/ListTable";

export const ListHeader = ({
    filter,
    handleClearSearch, 
    handleNextPage,
    toggleImportFileModal,
    handleSorting,
    setFilter
  }: any) => {
    const [value, setValue] = useState("one");

  return (
    <div className={` ${styles.tabs}`}>
      <Tabs
         value={value}
         onChange={(_, newValue) => setValue(newValue)}
         variant="scrollable"
         scrollButtons="auto"
          >
        <Tab value="one" label="Purchased" wrapped />
        <Tab value="two" label="Uploaded" wrapped />
      </Tabs>

      {value === "one" &&<ListTable
              handleSorting={handleSorting}
              filter={filter}
              handleNextPage={handleNextPage}
              handleClearSearch={handleClearSearch}
              toggleImportFileModal={toggleImportFileModal}
              type={ListsTabs.PURCHASED}
              setFilter={setFilter}
            ></ListTable>}
      {value === "two" && <ListTable
              handleSorting={handleSorting}
              filter={filter}
              handleNextPage={handleNextPage}
              handleClearSearch={handleClearSearch}
              toggleImportFileModal={toggleImportFileModal}
              type={ListsTabs.UPLOADED}
              setFilter={setFilter}
            ></ListTable>}
    </div>
  );
};
