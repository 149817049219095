import { useState, useRef, useEffect } from "react";
import { Controller } from "react-hook-form";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useDbounce } from "../hooks/debounce.hook";
import EmptyPlaceholder from "./EmptyPlaceholder";

interface TagProps {
  label: string;
  onDelete: () => void;
}

const Tag = ({ label, onDelete }: TagProps) => (
  <div className="multiselectScroll__tag">
    <span>{label}</span>
    <CloseIcon onClick={onDelete} />
  </div>
);

const MultiSelectScrollDropDown = ({
  options,
  label,
  control,
  name,
  errors,
  emptySearchMetaData,
  onSearchChange,
  onLoadMore,
  hasMore,
  isLoading,
}: any) => {
  const [searchValue, setSearchValue] = useState("");
  const [focused, setFocused] = useState(false); 
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setFocused(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const debouncedSearchValue = useDbounce(500, onSearchChange);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
    debouncedSearchValue(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const selectedLabels = field.value?.map((selectedItem: { value: any; label: string }) => selectedItem.label);

        return (
          <div className="multiselectScroll" ref={dropdownRef}>
            <div>
              <label>{label}</label>
              <div
                className={`multiselectScroll__input`}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
              >
                {selectedLabels?.map((label: string, index: number) => (
                  <Tag
                    key={index}
                    label={label}
                    onDelete={() => {
                      const updatedValues = field.value.filter(
                        (item: { value: any; label: string }) => item.label !== label
                      );
                      field?.onChange(updatedValues);
                    }}
                  />
                ))}
                <input
                  {...field}
                  ref={inputRef}
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => handleSearchChange(e.target.value)}
                />
              </div>
            </div>

            {focused && (
              <ul
                onScroll={(e: React.UIEvent<HTMLUListElement>) => {
                  const { scrollTop, scrollHeight, clientHeight } =
                    e.currentTarget;
                  if (
                    scrollHeight - scrollTop <= clientHeight + 10 &&
                    hasMore &&
                    !isLoading
                  ) {
                    onLoadMore();
                  }
                }}
              >
                {options.map((option: any) => {
                  const isSelected = field.value.some(
                    (selectedItem: { value: any; label: string }) => selectedItem.value === option.value
                  );

                  return (
                    <li
                      key={option.value}
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        const updatedValues = [...(field.value || [])];
                        const isSelected = updatedValues.some(
                          (item: { value: any; label: string }) => item.value === option.value
                        );

                        if (isSelected) {
                          const indexToRemove = updatedValues.findIndex(
                            (item: { value: any; label: string }) => item.value === option.value
                          );
                          updatedValues.splice(indexToRemove, 1);
                        } else {
                          updatedValues.push({ value: option.value, label: option.label });
                        }

                        field.onChange(updatedValues); 
                        handleSearchChange("")
                        setFocused(false);
                        inputRef.current?.blur()
                      }}
                      className={`${isSelected ? "selectedItem" : ""}`}
                    >
                      <span>{option.label}</span>
                      {isSelected && <CheckIcon fontSize="small" />}
                    </li>
                  );
                })}
                {!options.length && searchValue && (
                  <li>
                    <EmptyPlaceholder
                      icon={emptySearchMetaData.icon}
                      title={emptySearchMetaData.title}
                      description={emptySearchMetaData.description}
                    />
                  </li>
                )}
              </ul>
            )}

            {errors && errors?.[name] && (
              <span className="error">{errors?.[name]?.message}</span>
            )}
            {errors && errors?.message && (
              <span className="error">{errors?.message}</span>
            )}
          </div>
        );
      }}
    />
  );
};

export default MultiSelectScrollDropDown;
