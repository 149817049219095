import { useMap } from "@vis.gl/react-google-maps";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import ArrowLeft from "../../assets/icons/arrowLeft";
import FallbackPropertyImage from "../../assets/images/no-property-image-found.png";
import GoogleIcon from "../../assets/icons/google";

import locationMarker from "../../assets/images/location.svg";
import mapImg from "../../assets/images/map-img.jpeg";
import Button from "../../atoms/Button";
import Map, { MapViewMode } from "../../molecules/map";
import MarkerPopup from "../../molecules/markerPopup/MarkerPopup";
import styles from "./ImageMapView.module.scss";
import ArrowButton, { ArrowDirection } from "../../atoms/ArrowButton";
import { getPropertyDetailsData } from "./selector";
import ImageWithLoading from "../../atoms/ImageWithLoading";
import Camera from "../../assets/icons/camera";
import Street from "../../assets/icons/street";

enum ViewMode {
  STREET_VIEW = "STREET_VIEW",
  PHOTOS = "PHOTOS",
  MAP_VIEW = "MAP_VIEW",
  DEFAULT = "DEFAULT",
}

const settings = {
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  arrows: true,
  buttons: false,
  nextArrow: (
    <ArrowButton
      className={undefined}
      style={undefined}
      onClick={undefined}
      type={ArrowDirection.RIGHT}
    />
  ),
  prevArrow: (
    <ArrowButton
      className={undefined}
      style={undefined}
      onClick={undefined}
      type={ArrowDirection.LEFT}
    />
  ),
};

const ImageMapView = ({
  longitude,
  latitude,
  selectedMarkers,
  toggleMarker,
  propertyInformation,
  label,
  setSelectedMarkers,
  propertyfeaturesAsString,
  address,
}: {
  latitude: any;
  longitude: any;
  selectedMarkers: any;
  toggleMarker: Function;
  propertyInformation: any;
  label: any;
  setSelectedMarkers: React.Dispatch<React.SetStateAction<any[]>>;
  propertyfeaturesAsString: any;
  address: string;
}) => {
  const slider = useRef<any>(null);
  const map = useMap("property-detail-map");
  const [viewMode, setViewMode] = useState(ViewMode.DEFAULT);
  const zoomInit = useRef(true);
  const streetViewRef = useRef<HTMLDivElement | null>(null);

  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const markerRef = useRef<any>();

  useEffect(() => {
    if (streetViewRef.current) {
      // Initialize Street View
      const streetView = new google.maps.StreetViewPanorama(streetViewRef.current, {
        position: { lat: latitude, lng: longitude },
        pov: { heading: 0, pitch: 0 },
        zoom: 0,
      });

      // Set additional options
      streetView.setOptions({
        fullscreenControl: false,
        addressControl: false,
      });

      // Add a custom marker to the Street View
      markerRef.current = new google.maps.Marker({
        position: {
          lat: latitude ?? 0,
          lng: longitude ?? 0,
        },
        map: streetView,
        label: { text: address, color: "white" },
        draggable: false,
        icon: {
          url: locationMarker,
          labelOrigin: new google.maps.Point(30, 65),
          scaledSize: new google.maps.Size(60, 185),
        },
      });
    }
  }, [latitude, longitude, address]);

  useEffect(() => {
    let event: any;
    if (map) {
      event = map.addListener("tilesloaded", initialZoomSetHandler);
    }
    return () => {
      if (event) {
        google?.maps?.event?.removeListener(event);
      }
    };
  }, [map]);

  const initialZoomSetHandler = () => {
    if (zoomInit.current) {
      map?.setZoom(12);
      zoomInit.current = false;
    }
  };

  return (
    <div className={` ${styles.map}`}>
      {viewMode !== ViewMode.DEFAULT && (
        <div className={`flex ${styles.map__back}`}>
          <Button
            className="outline"
            prefix={<ArrowLeft />}
            label="Back"
            action={() => setViewMode(ViewMode.DEFAULT)}
          />
        </div>
      )}

      <div className={`flex ${styles.map__default}`}>
        <div
          className={` ${styles.map__Slider} ${
            viewMode === ViewMode.MAP_VIEW || viewMode === ViewMode.STREET_VIEW
              ? styles.hide
              : ""
          } `}
        >
          <Slider className="slick" {...settings} ref={slider}>
            {propertyDetails?.images?.length > 0 ? (
              propertyDetails?.images?.map((image: string) => (
                <div className={` ${styles.map__SliderImage}`}>
                  <ImageWithLoading
                    src={image || mapImg}
                    alt="map"
                  ></ImageWithLoading>
                </div>
              ))
            ) : (
              <div className={` ${styles.map__SliderImage}`}>
                <ImageWithLoading
                  src={propertyDetails?.propertyImageUrl || FallbackPropertyImage}
                  alt="map"
                ></ImageWithLoading>
              </div>
            )}
          </Slider>
        </div>
        <div
          className={` ${styles.map__defaultMap} ${
            viewMode === ViewMode.STREET_VIEW || viewMode === ViewMode.PHOTOS
              ? styles.hide
              : ""
          }  ${viewMode === ViewMode.MAP_VIEW ? styles.full : ""}`}
        >
          <Map
            mapId="property-detail-map"
            selectedMarkers={selectedMarkers}
            height="471px"
            toggleMarker={toggleMarker}
            markers={[
              {
                id: propertyDetails?.propertyId,
                coordinate: {
                  lat: latitude ? latitude : 0,
                  lng: longitude ? longitude : 0,
                },
                streetno: propertyInformation?.address,
                component: (
                  <MarkerPopup
                    onClose={() => {
                      setSelectedMarkers((prev) =>
                        prev.filter(
                          (marker) => marker !== propertyDetails?.propertyId
                        )
                      );
                    }}
                    headerText={label}
                    infoText={propertyfeaturesAsString}
                  ></MarkerPopup>
                ),
              },
            ]}
            coordinate={{
              lat: latitude ? latitude : 0,
              lng: longitude ? longitude : 0,
            }}
            initialViewMode={MapViewMode.ROADMAP}
            streetViewControl={false}
          />
        </div>

        <div
          className={` ${styles.map__defaultImages} ${
            viewMode === ViewMode.PHOTOS ? styles.showList : ""
          }`}
        >
          <div className="dflex">
            {propertyDetails?.images?.length > 0 ? (
              propertyDetails?.images?.map((image: string, index: string) => (
                <div className={` ${styles.map__ListImage}`}>
                  <ImageWithLoading
                    src={image || mapImg}
                    alt="map"
                    onClick={() => {
                      slider?.current.slickGoTo(index);
                    }}
                  ></ImageWithLoading>
                </div>
              ))
            ) : (
              <div className={` ${styles.map__ListImage}`}>
                <ImageWithLoading
                  src={propertyDetails?.propertyImageUrl || mapImg}
                  alt="map"
                ></ImageWithLoading>
              </div>
            )}
          </div>
        </div>

        <div
          id={"streetView"}
          ref={streetViewRef} // Attach ref for Street View container
          className={` ${styles.map__street} ${
            viewMode === ViewMode.STREET_VIEW ? styles.full : ``
          } `}
        ></div>
      </div>

      <div className={` ${styles.map__button} dflex alignCenter`}>
        {viewMode !== ViewMode.PHOTOS &&
          propertyDetails?.images &&
          propertyDetails?.images?.length > 0 && (
            <Button
              label="Photos"
              className={`outline borderGradient`}
              action={() => setViewMode(ViewMode.PHOTOS)}
              prefix={<Camera />}
            ></Button>
          )}

        {viewMode !== ViewMode.STREET_VIEW && (
          <Button
            label="Street View"
            className={`outline borderGradient`}
            prefix={<Street />}
            action={() => setViewMode(ViewMode.STREET_VIEW)}
          />
        )}
        {viewMode !== ViewMode.MAP_VIEW && (
          <Button
            label="Map View"
            className={`outline borderGradient`}
            prefix={<GoogleIcon />}
            action={() => {
              setTimeout(() => {
                map?.setZoom(17);
                map?.setMapTypeId(MapViewMode.SATELLITE);
              }, 500);
              setViewMode(ViewMode.MAP_VIEW);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ImageMapView;
