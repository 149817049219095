import React, { useEffect, useState } from "react";
import Button from "../../atoms/Button";
import CustomModal from "../../molecules/customModal/CustomModal";
import CreateCoupon from "../../components/createCoupon/CreateCoupon";
import { fetchCouponList, fetchCustomerList, fetchProductList } from "./sagaAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getCouponDetail,
  getCouponList,
  getCouponListLoading,
  getProductList,
} from "./selector";
import CustomTable from "../../molecules/table/CustomTable";
import Loader from "../../atoms/Loader";
import {  CouponTableColumns } from "./constants";
import Trash from "../../assets/icons/trash";
import ConfirmationModal from "../../components/confirmationModal/ConfirmationModal";
import { CustomError } from "../../models/customError.interface";
import { ToastType } from "../../molecules/toaster/enum";
import { addToast } from "../../molecules/toaster/toastSlice";
import { deleteStripeCoupon } from "./service";
import { setCouponList } from "./reducer";
import { followUpDateFormatter } from "../../utils/utils";
import { fetchOrganizationList } from "../organization/sagaAction";
import Plus from "../../assets/icons/plus";
import HeroSection from "../../organisms/heroSection/HeroSection";

const CouponsTab = () => {
  const dispatch = useDispatch();
  const couponList = useSelector((state) => getCouponList(state));
  const couponDetail = useSelector((state) => getCouponDetail(state));
  const couponListLoading = useSelector((state) => getCouponListLoading(state));
  const productList = useSelector((state) => getProductList(state));
  const [openCreateCouponModal, setOpenCreateCouponModal] =
    useState<boolean>(false);
  const [filter, setFilter] = useState({ limit: 10, startingAfter: "" });
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCouponData, setselectedCouponData] = useState({ id: "" });
  const handleClose = () => {
    setOpenCreateCouponModal(false);
  };

  const handleOpenCreateCouponModal = async () => {
    setOpenCreateCouponModal(true);
  };

  const handleNextPage = () => {
    setFilter((prev) => ({ ...prev, startingAfter: couponDetail?.lastId }));
  };

  const handleShowToast = (
    message: string,
    type: ToastType = ToastType.success
  ) => {
    dispatch(
      addToast({
        id: new Date().getTime(),
        message: message,
        type: type,
      })
    );
  };

  const handleDeleteCoupon = async () => {
    setLoading(true);
    try {
      await deleteStripeCoupon(selectedCouponData?.id);
      handleShowToast("Coupon deleted successfully!", ToastType.success);
      const updatedlist = couponList.filter(
        (el: any) => el.id !== selectedCouponData?.id
      );
      dispatch(setCouponList(updatedlist));
      setLoading(false);
      setShowDeleteModal(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
      handleShowToast(
        (err as CustomError)?.response?.data?.message ||
          "Something went wrong!",
        ToastType.error
      );
    }
  };

  useEffect(() => { 
    const listData = couponList?.map((list: any) => {
      const productNames = list?.applies_to?.products
      ? productList.items
          .filter((product: any) => list.applies_to.products.includes(product.id))
          .map((product: any) => product.name)
      : "-";

      const customerNames = list.customers?.map((customer: any) => customer.customerName) || "";
      const formattedDate = list?.expires && !isNaN(new Date(list?.expires).getTime())
    ? new Date(list?.expires)  
    : null;
      return { 
        coupon: `${list?.couponCode}`,
        terms: list?.terms,
        redemptions: list.redemptions ? `${list?.redemptions}` : "-",
        expires: 
        followUpDateFormatter(formattedDate),
        products: productNames,
        customers:customerNames,
        deleteCoupon: (
          <div className="flex gap-10 alignCenter">
            <div>
              <span
                className={`inlineFlex alignCenter button outline icon sm `}
                onClick={() => {
                  setselectedCouponData(list);
                  setShowDeleteModal(true);
                }}
              >
                <Trash />
              </span>
            </div>
          </div>
        ),
      };
    });
 
    setTableData(listData ?? []);
  }, [couponList,productList]); 

  useEffect(() => {
    dispatch(fetchProductList({}));
    dispatch(fetchCustomerList({size:30,index:1}));
    dispatch(fetchOrganizationList({ index: 1, size: 20, search: "" }));
  }, []);

  useEffect(() => {
    
    dispatch(fetchCouponList(filter));
    
  }, [filter]);

  return (
    <>
      <HeroSection title="Coupon">
        <Button
            className="primary"
            label="Create Coupon"
            prefix={<Plus />}
            action={() => {
              handleOpenCreateCouponModal();
            }}
          ></Button>
        </HeroSection>
      <CustomModal
        modalTitle={"Create Coupon"}
        handleClose={handleClose}
        open={openCreateCouponModal}
        customClass="dialogLarge"
      >
        <CreateCoupon handleClose={handleClose} />
      </CustomModal>

      {couponListLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <CustomTable
          rows={tableData}
          columns={CouponTableColumns}
          search={""}
          tableStriped
          hasMore={couponDetail?.hasMore}
          handleNextPage={handleNextPage}
          lastColSticky
        ></CustomTable>
      )}

      <ConfirmationModal
        isOpen={showDeleteModal}
        setIsOpen={setShowDeleteModal}
        buttonComponent={
          <Button
            label="Yes, Delete"
            className="red xl full "
            action={handleDeleteCoupon}
            disabled={loading}
            clicked={loading}
          />
        }
      >
        <h2>Delete Coupon</h2>
        <p>
          Are you sure you want to delete this coupon? <br />
          This action cannot be undone.
        </p>
      </ConfirmationModal>
    </>
  );
};

export default CouponsTab;
