import { format as formatDate } from "date-fns";
import { useEffect, useState } from "react";
import styles from "./FollowUpTab.module.scss";
import EmptyPlaceholder from "../../atoms/EmptyPlacholder";
import { useSelector } from "react-redux";
import { PropertyDetailsParentIdType } from "../propertyDetail/property.enum";
import Follow from "../../assets/icons/follow";
import NotesTab from "../propertyDetail/propertyTabs/notesTab/NotesTab";
import { getPropertyDetailsData } from "../propertyDetail/selector";

const FollowUpTab = () => {
  const [followUpDate, setFollowUpDate] = useState<Date | undefined>(undefined);
  const [displayCalender, setDisplayCalender] = useState(false);
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const { followUpAt } = propertyDetails;
  useEffect(() => {
    if (
      propertyDetails &&
      Object.keys(propertyDetails).length > 0 &&
      propertyDetails?.followUpAt
    ) {
      setFollowUpDate(new Date(propertyDetails.followUpAt));
    }
  }, [propertyDetails]);

  return (
    <>
      <div className={` ${styles.follow} dflex alignCenter`}>
        <div className={` ${styles.follow__Placeholder} `}>
          {!displayCalender ? (
            <EmptyPlaceholder
              icon={<Follow />}
              title={
                !followUpDate ? "Let’s Close That Deal!" : "Follow Up Scheduled"
              }
              description={
                followUpDate
                  ? formatDate(followUpDate, "MMMM dd, yyyy")
                  : "It’s a fact: 75% of deals close after the follow-up. Time to take action and schedule yours now!"
              }
              bgGray={true}
              extraStyles={{
                maxWidth: "450px",
                paddingBottom: 0,
                minHeight: 0,
              }}
              blared
            />
          ) : (
            <></>
          )}
        </div>

        <div className={` ${styles.follow__note}`}>
          <NotesTab />
        </div>
      </div>
    </>
  );
};
export default FollowUpTab;
