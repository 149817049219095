import React, { useCallback, useEffect, useState } from "react";
import Button from "../../atoms/Button";
import CustomTable from "../../molecules/table/CustomTable";
import Loader from "../../atoms/Loader";
import {
  getListLoading,
  getMyListCount,
  getMylistData,
} from "../../organisms/list/selector";
import { useDispatch, useSelector } from "react-redux";
import { fetchExportCSVList } from "../../organisms/list/service";
import { useNavigate, useParams } from "react-router-dom";
import { useDbounce } from "../../hooks/debounce.hook";
import { fetchLeadListDetail, fetchList } from "../../organisms/list/sagaAction";
import { getProfileInfo } from "../profile/selector";
import myAuditLogs from "@ottoleads/ottoleads_log_aggregation";
import { sendActivityLog } from "../../organisms/auth/service";
import { CircularProgress, Tooltip } from "@mui/material";
import AlertTriangle from "../../assets/icons/alertTriangle";
import ExportToCsv from "../../atoms/ExportToCsvV2";
import {
  column,
  columns,
  skipList_column,
} from "../../organisms/list/constants";
import {
  formartNumberAsLocalString,
  formatMonthYear,
  timeAgoFormatter,
} from "../../utils/utils";
import SearchReflation from "../../assets/icons/searchReflation";
import styles from "./ListTable.module.scss";
import { setMyLists } from "../../organisms/list/reducer";
import { fetchPropertyDetail } from "../propertyDetail/sagaAction";

const ListTable = ({
  filter,
  handleClearSearch,
  handleNextPage,
  toggleImportFileModal,
  handleSorting,
  type,
  setFilter,
}: any) => {
  const { orgId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const listLoading = useSelector((state) => getListLoading(state));
  const [tableData, setTableData] = useState<Record<string, any>[]>([]);
  const profileInfo = useSelector((state) => getProfileInfo(state));
  const myLists = useSelector((state) => getMylistData(state));
  const totalCount = useSelector((state) => getMyListCount(state));
  const [selectedList, setSelectedList] = useState({id:"",name:""});
  const [selectedId, setSelectedId] = useState(-1);
  const [listOpenModal, setListOpenModal] = useState(false);

  const handleCSVData = async (data: any) => {
    setSelectedId(data);
    try {
      const response = await fetchExportCSVList({ id: data, orgId });
      const listingData = response?.data?.data?.map((list: any) => {
        return {
          nameFirst: list?.owner[0]?.nameFirst ?? "",
          nameLast: list?.owner[0]?.nameLast ?? "",
          addressStreet: list?.addressStreet ?? "",
          addressCity: list?.addressCity ?? "",
          addressState: list?.addressState ?? "",
          addressZip: list?.addressZip ?? "",
          phone1: list?.phones[0]?.number ?? "",
          phone2: list?.phones[1]?.number ?? "",
          phone3: list?.phones[2]?.number ?? "",
        };
      });
      return listingData;
    } catch (err) {
    } finally {
      setSelectedId(-1);
    }
  };

  const handleSkipData = async (data: any) => {
    setSelectedId(data);
    try {
      const response = await fetchExportCSVList({ id: data, orgId });
      const listingData = response?.data?.data?.map((list: any) => {
        return {
          Owner1FirstName:`"${ list.skipData?.owners?.[0]?.nameFirst ?? list?.searchData?.jsonData?.owner1FirstName ?? "" }"`,
          Owner1LastName:
           `"${ list.skipData?.owners?.[0]?.nameLast ?? list?.searchData?.jsonData?.owner1LastName ??""}"`,
          Owner2FirstName:
           `"${ list.skipData?.owners?.[1]?.nameFirst ?? list?.searchData?.jsonData?.owner2FirstName ??
            "" }"`,
          Owner2LastName:
           `"${ list.skipData?.owners?.[1]?.nameLast ?? list?.searchData?.jsonData?.owner2LastName ??""}"`,
          MailingStreetAddress:
           `"${ list.skipData?.mailingAddress?.street ?? list?.searchData?.jsonData?.mailAddress?.street ?? ""}"`,
          MailingCity: `"${list.skipData?.mailingAddress?.city ??list?.searchData?.jsonData?.mailAddress?.city ??  ""}"`,
          MailingState: `"${list.skipData?.mailingAddress?.state ??list?.searchData?.jsonData?.mailAddress?.state ??  ""}"`,
          MailingZip:`"${ list.skipData?.mailingAddress?.zip ?? list?.searchData?.jsonData?.mailAddress?.zip ?? ""}"`,
          MailingCounty: `"${list.skipData?.mailingAddress?.county ?? list?.searchData?.jsonData?.mailAddress?.county ?? ""}"`,
          PropertyStreetAddress:
           `"${list.skipData?.propertyAddress?.street ??list?.searchData?.jsonData?.address?.street ??  ""}"`,
          PropertyCity: `"${list.skipData?.propertyAddress?.city ??list?.searchData?.jsonData?.address?.city ??"" }"`,
          PropertyState: `"${list.skipData?.propertyAddress?.state ?? list?.searchData?.jsonData?.address?.state ??""}"`,
          PropertyZip:`"${ list.skipData?.propertyAddress?.zip ?? list?.searchData?.jsonData?.address?.zip ?? ""}"`,
          PropertyCounty: `"${list.skipData?.propertyAddress?.county ??list?.searchData?.jsonData?.address?.county ?? ""}"`,
          Email1:
                `"${list.skipData?.emails?.[0]?.email ?? ""}"`,
          Email2:
            `"${list.skipData?.emails?.[1]?.email ?? ""}"`,
          Phone1:
            `"${list.skipData?.phoneNumbers?.[0]?.number ?? ""}"`,
          Phone2:
          `"${list.skipData?.phoneNumbers?.[1]?.number ?? ""}"`,
          Phone3:
          `"${list.skipData?.phoneNumbers?.[2]?.number ?? ""}"`,
          Phone4:
            `"${list.skipData?.phoneNumbers?.[3]?.number ?? ""}"`,
          Phone5:
          `"${list.skipData?.phoneNumbers?.[4]?.number ?? ""}"`,
          Phone6:
          `"${list.skipData?.phoneNumbers?.[5]?.number ?? ""}"`,
          IsVacant: `"${list.skipData?.isVacant ?? ""}"`,
          IsAbsentee: `"${list.skipData?.isAbsentee ?? ""}"`,
          APN: `"${list?.skipData?.apn ?? ""}"`,
          PropertyType:`"${list.skipData?.propertyType ?? "" }"`, 
          PropertyUse: `"${list?.skipData?.propertyUse ?? ""}"`,
          BedroomCount: `"${list.skipData?.bedroomCount ?? ""}"`,
          BathroomCount: `"${list.skipData?.bathroomCount ?? ""}"`,
          BuildingSquareFeet: `"${list.skipData?.BuildingSquareFeet ?? ""}"`,
          LotSquareFeet: `"${list?.skipData?.lotSquareFeet ?? ""}"`,
          YearBuilt:`"${list.skipData?.yearBuilt ?? ""}"`,
          AssessedValue: `"${list.skipData?.assessedValue  ?? ""}"`,
          AssessedLandValue: `"${list.skipData?.assessedLandValue  ?? ""}"`,
          AssessedImprovementValue: `"${list.skipData?.AssessedImprovementValue ?? ""}"`,
          LastSaleDate: `"${formatMonthYear(list.skipData?.lastSaleDate) ?? ""}"`,
          LastSalePrice: `"${list.skipData?.lastSalePrice  ?? ""}"`,
          MortgageBalanceOpen:
            `"${list.skipData?.openMortgageBalance ?? ""}"`,
          EstimatedEquity: `"${list.skipData?.estimatedEquity ?? ""}"`, 
          EstimatedValue: `"${list.skipData?.estimatedValue ?? ""}"`,
          MLSStatus: `"${list.skipData?.mls?.status ?? ""}"`,
          MLSListingDate: `"${formatMonthYear(list.skipData?.mls?.originalListingDate) ?? ""}"`,
          MLSListingPrice: `"${list.skipData?.mls?.price ?? ""}"`,
            AISummary: `"${list?.aiSummary ?? ""}"`
        };
      });
      setSelectedId(-1);
      return listingData;
    } catch (err) {
      setSelectedId(-1);
    }
  };

  const logAuditData = async (data: any, logType: any) => {
    const attributes = {
      action: data,
      userId: profileInfo?.id,
      uuid: "99",
      email: profileInfo.email,
      roleName: profileInfo.roleName,
    };
    try {
      const result = await myAuditLogs(attributes);
      console.log(result);
      try {
        const res = await sendActivityLog({
          logType: logType,
          jsonData: result,
        });
        console.log(res);
      } catch (error) {
        console.error("Error logging audit data:", error);
      }
    } catch (error) {
      console.error("Error logging audit data:", error);
    }
  };

  const handleDownload = async (type: string, id: any) => {
    logAuditData(`User is downloading list id : ${id}`, "Download List");
    if (type === "uploaded") {
      return await handleCSVData(id);
    } else {
      return await handleSkipData(id);
    }
  };

  const handleRowOnClick = (id: string , type: string,detail:any) => {
    if (type === "ottolead") {
      navigate(`/organisation/${orgId}/list/${id}`);
    }
  };

  const debounce = useDbounce(500, (filter: any) => {
    const payload = { 
      index: filter?.index,
      size: filter?.size,
      orgId,
      search: filter?.search,
      type: type,
    };
    dispatch(fetchList(payload));
  });
  const handleList = useCallback(() => {
    let list = myLists?.map((lead: any) => {
      const downloadListStatus = () => {
        if (lead?.status === "uploading") {
          return (
            <div className="disable-icon">
              <CircularProgress size={20} />
            </div>
          );
        } else if (lead?.status === "failed") {
          return (
            <Tooltip title={"Failed"}>
              <div className="disable-icon">
                <AlertTriangle />
              </div>
            </Tooltip>
          );
        } else if (lead?.status === "success") {
          return (
            <span role="link">
              <ExportToCsv
                headers={lead?.type === "uploaded" ? column : skipList_column}
                filename={`${lead?.name}.csv`}
                loadData={() => handleDownload(lead?.type, lead?.id)}
                loading={selectedId === lead?.id}
              />
            </span>
          );
        }
      };
      return {
        id: lead?.id,
        list: {
          title: lead?.name ?? "",
          description:
            lead?.status !== "failed"
              ? lead?.type === "ottolead"
                ? "Purchased"
                : lead?.type === "uploaded"
                ? "Uploaded"
                : lead?.type
              : "Failed",
          status: lead?.status,
        },
        uploadDate: timeAgoFormatter(new Date(lead?.createdAt)),
        handleOnClicked: () => handleRowOnClick(lead.id,lead?.type,lead),
        records:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.recordCount ?? 0)
            : "-",
        phone:
          lead?.status !== "uploading"
            ? formartNumberAsLocalString(lead?.phonesCount ?? 0)
            : "-",
        downloadList: downloadListStatus(),
      };
    });
    setTableData(list ?? []);
  }, [myLists, selectedId]);

  useEffect(() => {
    handleList();
  }, [myLists, selectedId]);

  useEffect(() => {
    debounce(filter);
  }, [orgId, filter]);

  useEffect(() => {
    return () => {
      dispatch(setMyLists([]));
      setFilter({
        index: 1,
        size: 20,
        search: ""
      });
    };
  }, []);
  return (
    <>
      {listLoading ? (
        <div className="table-loader">
          <Loader></Loader>
        </div>
      ) : (
        <div className={`${styles.list__table}`}>
          <CustomTable
            rows={tableData}
            columns={columns}
            search={filter.search}
            tableStriped
            hasMore={tableData.length < totalCount}
            handleNextPage={handleNextPage}
            emptySearchMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
              description:
                "We couldn’t find any results that match your search.",
              button1: (
                <Button
                  label="Clear search"
                  className="outline"
                  action={handleClearSearch}
                />
              ),
            }}
            emptyTableMetaData={{
              icon: <SearchReflation />,
              title: "No results found",
            }}
          ></CustomTable>
        </div>
      )}
    </>
  );
};

export default ListTable;
