import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import DropDown from "../../atoms/Dropdown";
import Loader from "../../atoms/Loader";
import { LeadStatusMap } from "../../constant/leadStatus.constant";
import { formartNumberAsLocalString } from "../../utils/utils";
import styles from "./PropertyDetails.module.scss";
import PropertyTabs from "./propertyTabs/PropertyTabs";

import { getPropertyDetailsData, isLoading } from "./selector";
import { propertyUpdateDetailsAction } from "./sagaAction";
import ImageMapView from "./ImageMapView";
import { fetchNoteCount } from "./service";
import ArrowLeft from "../../assets/icons/arrowLeft";

const LeadStatusOptions = Object.entries(LeadStatusMap).map(([key, value]) => ({
  label: key,
  value: value,
}));

const PropertyDetail = () => {
  const propertyDetails = useSelector((state) => getPropertyDetailsData(state));
  const { status = "", follow_up_at = "", addedOwner } = propertyDetails;
  const isPropertyDetailsLoading = useSelector((state) => isLoading(state));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { orgId,id,listid } = useParams();
  const param = useParams();
  const nameRef = useRef("");
  const [showSingleSkipTraceModal, setshowSingleSkipTraceModal] =
    useState(false);
  const [ownerFullName, setOwnerFullName] = useState("");

  const {
    formState: { errors },
    control,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      follow_up_at: follow_up_at ?? "",
      status: status,
    },
  });
  const [selectedMarkers, setSelectedMarkers] = useState<any[]>([]);
  const [showAddContactModal, setShowAddContactModal] = useState(false);
  const [showNoteCount, setShowNoteCount] = useState(0);

  const handleClickSkipTrace = () => {
    setshowSingleSkipTraceModal(true);
  };
  const handleClickAddContact = () => {
    setShowAddContactModal(!showSingleSkipTraceModal);
  };

  useEffect(() => {
    if (propertyDetails?.leadId) {
      const notesCount = async () => {
        const res = await fetchNoteCount(propertyDetails?.leadId, orgId);
        setShowNoteCount(res.data.commentCount);
      };
      notesCount();
    }
  }, [propertyDetails, orgId]);

  useEffect(() => {
    if (follow_up_at || status) {
      if (follow_up_at !== watch("follow_up_at")) {
        setValue("follow_up_at", follow_up_at);
      }
      if ((LeadStatusMap as any)?.[status] !== watch("status")) {
        setValue("status", (LeadStatusMap as any)?.[status]);
      }
      reset({
        follow_up_at: follow_up_at,
        status: (LeadStatusMap as any)?.[status],
      });
    }
  }, [follow_up_at, status]);

  useEffect(() => {
    const updateName = addedOwner
      ? `${addedOwner.nameFirst} ${
          addedOwner?.nameLast ? addedOwner?.nameLast : ""
        }`
      : propertyDetails?.jsonData?.ownerInfo?.owner1FullName ?? "-";
    nameRef.current = updateName;
    setOwnerFullName(updateName);
  }, [propertyDetails]);

  if (isPropertyDetailsLoading) {
    return (
      <div className="flex alignCenter justifyCenter table-loader">
        <Loader />
      </div>
    );
  }

  if (!isPropertyDetailsLoading && !propertyDetails?.leadId) {
    navigate(`/organisation/${orgId}/leads`, { replace: true });
    return null;
  }

  const { propertyInformation, lot, longitude, latitude } = propertyDetails;

  let addressStr = propertyDetails?.mailingAddress;
  const label = addressStr ? addressStr : "-";

  const features: {
    [key: string]: any;
  } = {
    bed: propertyInformation?.bedroomCount
      ? `<strong>
        ${propertyInformation?.bedroomCount}
        </strong>`
      : "-",
    bath: propertyInformation?.bathroomCount
      ? `<strong>
          ${propertyInformation?.bathroomCount}
        </strong>`
      : "-",
    sqft: propertyInformation?.livingAreaSquareFeet
      ? ` <strong>
          ${formartNumberAsLocalString(
            propertyInformation?.livingAreaSquareFeet
          )}
        </strong>`
      : "-",
    acres: lot?.lotSizeAcres
      ? `<strong>
        ${formartNumberAsLocalString(lot?.lotSizeAcres)}
        </strong>`
      : "-",
    "year built": propertyInformation?.yearBuilt
      ? `<strong>
        ${propertyInformation?.yearBuilt}
        </strong>`
      : "-",
  };

  const handleStatusChange = (statusValue: any) => {
    dispatch(
      propertyUpdateDetailsAction({
        id: param.id,
        status: statusValue?.label,
        organisationId: orgId,
      })
    );
  };

  const propertyfeaturesAsString = Object.keys(features)
    .map((key) => (features[key] ? `${features[key]} ${key}` : ""))
    .filter(Boolean)
    .join(" | ");

  const handleClose=()=>{
    if(listid){
      navigate(`/organisation/${orgId}/list/${id}`);
    }else{
      navigate(`/organisation/${orgId}/leads`);
    }
  }

  const AboveMap = () => {
    return (
      <div className={` ${styles.property__top}`}>
        
        <div className={` ${styles.propertyHeader} dflex justifySpaceBetween`}>
          
          <div className={` ${styles.propertyHeader__left}`}>
          <span
        role="link"
        className={` ${styles.propertyHeader__leftBack} flex alignCenter`}
        onClick={() => handleClose()}
      >
        <ArrowLeft />
        Back to {listid? "List":"Lead"}
      </span>
<h2>{label}</h2>
            {/* <p>{propertyfeaturesAsString}</p> */}
            <p
              dangerouslySetInnerHTML={{ __html: propertyfeaturesAsString }}
            ></p>
          </div>
          <div className={`dflex alignCenter ${styles.propertyHeader__right}`}>
            <DropDown
              options={LeadStatusOptions}
              clearOnBlur={true}
              label=""
              control={control}
              name="status"
              errors={errors}
              placeholder="Status"
              handleOnChange={handleStatusChange}
              disabled={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const toggleMarker = (id: number | string) => {
    if (selectedMarkers.includes(id)) {
      setSelectedMarkers((prev) => prev.filter((marker) => marker !== id));
    } else {
      setSelectedMarkers((prev) => [...prev, id]);
    }
  };

  
  return (
    <>
      {/* <LeadActivityDrawer
          propertyId={param.id || ""}
          showCommentBox={showCommentBox}
          setShowpopup={setShowCommentBox}
        /> */}
      
      <div className={` ${styles.property}`}>
        
        {/* <Header loggedIn></Header> */}
        <AboveMap />
        <div className={` ${styles.property__map}`}>
          <ImageMapView
            longitude={longitude}
            latitude={latitude}
            selectedMarkers={selectedMarkers}
            toggleMarker={toggleMarker}
            propertyInformation={propertyInformation}
            label={label}
            setSelectedMarkers={setSelectedMarkers}
            propertyfeaturesAsString={propertyfeaturesAsString}
            address={propertyDetails?.mailingAddress ?? ""}
          ></ImageMapView>
        </div>
        <div className={` ${styles.property__tabs}`}>
          <PropertyTabs
            handleClickSkipTrace={handleClickSkipTrace}
            handleClickAddContact={handleClickAddContact}
            showNoteCount={showNoteCount}
          />
        </div>
      </div>
    </>
  );
};

export default PropertyDetail;
